import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
   
    {
        path: "/login",
        name: "login",
        component: () => import("../view/login/Login.vue"),
        beforeEnter(to, from, next){
            if (localStorage['tokenSkopos']){
               next('/')
            } else {
               next()
            }
        }
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../view/registrar/Register.vue"),
        beforeEnter(to, from, next){
            if (localStorage['tokenSkopos']){
               next('/')
            } else {
               next()
            }
        }
    },
    {
        path: "/reset/senha/:hash",
        name: "reset",
        component: () => import("../view/login/Reset.vue")
    },
    {
        path: "/",
        component: () => import("../view/Layout.vue"),
        children: [
            {
                path: "/",
                name: "home",
                component: () => import("../view/Home.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                }
            },
            {
                path: "/cadastro",
                name: "cadastroNew",
                component: () => import("../view/cadastro/Cadastro.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                }
            },
            {
                path: "/cadastro/:cadastroId",
                name: "cadastro",
                component: () => import("../view/cadastro/Cadastro.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                }
            },
            {
                path: "/consulta/cadastro",
                name: "cadastroConsulta",
                component: () => import("../view/cadastro/Consulta.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                }
            },
            {
                path: "/register/:userId",
                name: "registerMeusDados",
                component: () => import("../view/registrar/MeusDados.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                }
            },
            {
                path: "/operacao",
                name: "operacaoNew",
                component: () => import("../view/operacional/Operacional.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                }
            },
            {
                path: "/operacao/:operacaoId",
                name: "operacao",
                component: () => import("../view/operacional/Operacional.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                }
            },
            {
                path: "/consulta/operacao",
                name: "operacaoConsulta",
                component: () => import("../view/operacional/Consulta.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                }
            },
            {
                path: "/medicoes",
                name: "operacaoMedicoes",
                component: () => import("../view/operacional/LayoutMedicoes.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                },
                children:[
                    {
                        path: "/medicoes",
                        name: "operacaoMedicoesListar",
                        component: () => import("../view/operacional/Medicoes.vue"),
                    },
                    // {
                    //     path: "/medicoes/operacoes",
                    //     name: "OperacoesMedicoes",
                    //     component: () => import("../view/operacional/OperacoesMedicoes.vue"),
                    // },
                ]
            },
            {
                path: "/fechamentoMensal",
                name: "fechamentoMensal",
                component: () => import("../view/fechamentoMensal/LayoutFechamentoMensal.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                },
                children:[
                    {
                        path: "/fechamentoMensal",
                        name: "fechamentoMensalOperacao",
                        component: () => import("../view/fechamentoMensal/FechamentoOperacao.vue"),
                    },
                    {
                        path: "/fechamentoMensal/reajustePreco",
                        name: "reajusteDePreco",
                        component: () => import("../view/fechamentoMensal/ReajusteDePreco.vue"),
                    },
                    {
                        path: "/fechamentoMensal/dri",
                        name: "dri",
                        component: () => import("../view/fechamentoMensal/Dri.vue"),
                    },
                    {
                        path: "/fechamentoMensal/driRetusd",
                        name: "driRetusd",
                        component: () => import("../view/fechamentoMensal/DriRetusd.vue"),
                    },
                ]
            },

            {
                path: "/contratoCcee",
                name: "contratoCcee",
                component: () => import("../view/contratosCcee/LayoutContratosCcee.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                },
                children:[
                    {
                        path: "/contratoCcee/operacoesVenda",
                        name: "operacoesVendaContratosCcee",
                        component: () => import("../view/contratosCcee/OperacoesVendas.vue"),
                    },
                    {
                        path: "/contratoCcee/operacoesCompra",
                        name: "operacoesCompraContratosCcee",
                        component: () => import("../view/contratosCcee/OperacoesCompra.vue"),
                    },
                    {
                        path: "/contratoCcee/contratosCceeListaVendas",
                        name: "contratosCceeListaVendas",
                        component: () => import("../view/contratosCcee/ContratosCceeVendas.vue"),
                    },
                    {
                        path: "/contratoCcee/contratosCceeListaCompras",
                        name: "contratosCceeListaCompras",
                        component: () => import("../view/contratosCcee/ContratosCceeCompras.vue"),
                    }
                ]
            },
            {
                path: "/consulta/pipeline",
                name: "LayoutConsulta",
                component: () => import("../view/comercial/LayoutConsulta.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                }
            },
            {
                path: "/admin",
                name: "admin",
                component: () => import("../view/admin/Admin.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                }
            },
            {
                path: "/parametros",
                name: "LayoutParametros",
                component: () => import("../view/parametros/LayoutParametros.vue"),
                children:[
                    {
                        path: "/parametros/parametrosMensal",
                        name: "parametros",
                        component: () => import("../view/parametros/ParametrosMensal.vue"),
                    },
                ]
            },
            {
                path: "/risco",
                name: "risco",
                component: () => import("../view/risco/LayoutRisco.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                },
                children:[
                    {
                        path: "/risco/comercializador",
                        name: "comercializador",
                        component: () => import("../view/risco/Comercializadora.vue"),
                    },
                    {
                        path: "/risco/consumidor",
                        name: "consumidor",
                        component: () => import("../view/risco/Consumidor.vue"),
                    },
                ]
            },
            {
                path: "/risco/solicitar",
                name: "riscoSolicitar",
                component: () => import("../view/risco/SolicitarAnalise.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                        next()
                    } else {
                        next('/login')
                    }
                }
            },
            {
                path: "/risco/:agentId",
                name: "riscoAgente",
                component: () => import("../view/risco/LayoutRisco.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                    next()
                    } else {
                    next('/login')
                    }
                }
            },
            {
                path: "/juridico/contratosPendentes",
                name: "contratosPendentes",
                component: () => import("../view/juridico/ContratoPendente.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                    next()
                    } else {
                    next('/login')
                    }
                }
            },
            {
                path: "/juridico/contratos",
                name: "contratos",
                component: () => import("../view/juridico/Contratos.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                    next()
                    } else {
                    next('/login')
                    }
                }
            },
            {
                path: "/juridico/contratosCp",
                name: "contratosCp",
                component: () => import("../view/juridico/ContratosCp.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                    next()
                    } else {
                    next('/login')
                    }
                }
            },
            {
                path: "/gerencial",
                name: "gerencial",
                component: () => import("../view/gerencial/LayoutGerencial.vue"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                       next()
                    } else {
                       next('/login')
                    }
                },
                children:[
                    {
                        path: "/gerencial/relatorioFechamento",
                        name: "relatorioFechamento",
                        component: () => import("../view/gerencial/RelatorioFechamento.vue"),
                    },
                    {
                        path: "/gerencial/riscoconsumidor",
                        name: "riscoconsumidor",
                        component: () => import("../view/gerencial/riscoconsumidor.vue"),
                    },
                    {
                        path: "/gerencial/mtm",
                        name: "mtm",
                        component: () => import("../view/gerencial/mtm.vue"),
                    },
                    {
                        path: "/gerencial/gerencialConsulta",
                        name: "gerencialConsulta",
                        component: () => import("../view/gerencial/GerencialConsulta.vue"),
                    },
                ]
            },
            {
                path: "/minuta/cadastrar",
                name: "CadastrarMinuta",
                component: () => import("../view/Minutas/CadastrarMinuta"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                        next()
                    } else {
                        next('/login')
                    }
                }
            },
            {
                path: "/minuta",
                name: "ListarMinuta",
                component: () => import("../view/Minutas/ListarMinuta"),
                beforeEnter(to, from, next){
                    if (localStorage['tokenSkopos']){
                        next()
                    } else {
                        next('/login')
                    }
                }
            },
            {
                path: "/precos",
                name: "PrecoLayout",
                component: () => import("../view/gerencial/LayoutPrecos.vue"),
                children:[
                    {
                        path: "/precos",
                        name: "PrecoGerencial",
                        component: () => import("../view/gerencial/PrecoGerencial.vue"),
                    },
                    {
                        path: "/precos/var",
                        name: "VarGerencial",
                        component: () => import("../view/gerencial/VarGerencial.vue"),
                    },
                    {
                        path: "/parametros/descontoIncentivada",
                        name: "ParametroDescontoIncentivada",
                        component: () => import("../view/gerencial/ParametroDescontoIncentivada.vue"),
                    },
                    {
                        path: "/parametros/resultadosGerados",
                        name: "ParametroResultadosGerados",
                        component: () => import("../view/gerencial/ParametroResultadosGerados.vue"),
                    },
                ]
            },
            {
                path: "/varejista",
                name: "LayoutVarejista",
                component: () => import("../view/varejista/LayoutVarejista.vue"),
                children:[
                    {
                        path: "/varejista/clientes",
                        name: "ClientesVarejistas",
                        component: () => import("../view/varejista/Clientes.vue"),
                    },
                    {
                        path: "/varejista/precos",
                        name: "PrecosVarejistas",
                        component: () => import("../view/varejista/Precos.vue"),
                    },
                ]
            },

            {
                path: "/notificacao",
                name: "NotificacaoLayout",
                component: () => import("../view/Notificacoes/LayoutNotificacoes.vue"),
                children:[
                    {
                        path: "/notificacao/eventos",
                        name: "Eventos",
                        component: () => import("../view/Notificacoes/Eventos.vue"),
                    },
                    {
                        path: "/notificacao/alert",
                        name: "Notificacoes",
                        component: () => import("../view/Notificacoes/Notificacoes.vue"),
                    },
                    {
                        path: "/notificacao/sistema",
                        name: "NotasSistema",
                        component: () => import("../view/Notificacoes/NotasDoSistema.vue"),
                    },
                ]
            },

            {
                path: "/financeiro",
                name: "LayoutFinanceiro",
                component: () => import("../view/Financeiro/LayoutFinanceiro.vue"),
                children:[
                    {
                        path: "/financeiro/validacaoBancaria",
                        name: "financeiroValidacaoBancaria",
                        component: () => import("../view/Financeiro/FinanceiroValidacaoBancaria.vue"),
                    },
                    {
                        path: "/financeiro/vendas",
                        name: "financeiroVendas",
                        component: () => import("../view/Financeiro/FinanceiroVendas.vue"),
                    },
                    {
                        path: "/financeiro/compras",
                        name: "FinanceiroCompras",
                        component: () => import("../view/Financeiro/FinanceiroCompras.vue"),
                    },
                    {
                        path: "/financeiro/notas",
                        name: "FinanceiroNotas",
                        component: () => import("../view/Financeiro/FinanceiroNotas.vue"),
                    },
                    {
                        path: "/financeiro/pendencias",
                        name: "FinanceiroPendencias",
                        component: () => import("../view/Financeiro/FinanceiroPendencias.vue"),
                    },
                    {
                        path: "/financeiro/layoutNotas",
                        name: "FinanceiroLayoutNotas",
                        component: () => import("../view/Financeiro/FinanceiroLayoutNotas.vue"),
                    },
                    {
                        path: "/financeiro/net",
                        name: "FinanceiroNet",
                        component: () => import("../view/Financeiro/FinanceiroNet.vue"),
                    },
                    {
                        path: "/financeiro/orderPagamento",
                        name: "FinanceiroOrdemPagamento",
                        component: () => import("../view/Financeiro/OrdemPagamento.vue"),
                    },
                    {
                        path: "/financeiro/ccee",
                        name: "financeiroCcee",
                        component: () => import("../view/Financeiro/Ccee.vue"),
                    },
                ]
            },

            {
                path: "/comissao",
                name: "LayoutComissao",
                component: () => import("../view/comissao/LayoutComissao.vue"),
                children:[
                    {
                        path: "/comissao/contrapartes",
                        name: "comissaoContrapartes",
                        component: () => import("../view/comissao/ComissaoContrapartes.vue"),
                    },
                    {
                        path: "/comissao/operacoes",
                        name: "ComissaoOperacoes",
                        component: () => import("../view/comissao/ComissaoOperacoes.vue"),
                    }
                ]
            },
            {
                path: "/relatorios",
                name: "LayoutRelatoriosFinanceiro",
                component: () => import("../view/Financeiro/LayoutRelatoriosFinanceiro.vue"),
                children:[
                    {
                        path: "/relatorios/operacoesNotas",
                        name: "operacoesNotas",
                        component: () => import("../view/Financeiro/RelatorioOperacoesNotas.vue"),
                    }
                ]
            },
        ]
    },
    // {
    //     path: '*',
    //     name: 'NotFound',
    //     banner: true,
    //     component: () => import('../pages/NotFound.vue'),
    // },
];
const router = new VueRouter({
    mode: "history",
    routes
});
export default router;
